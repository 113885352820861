export const Routing = {
  Initial: "/",
  Admin: "/admin",
  Login: "/admin/login",
  ResetPassword: "/admin/reset-password",
  Register: "/admin/register",
  PrivacyPolicy: "/policies",
  TermsConditions: "/tnc",
  Payment:"/payment/:id/:phone/:name/:amount/:token",
  Faq: "/faq's",
  About: "/contact-us",
  Listeners: "/listeners",
  BeListener: "/be-listener",
  Refund: "/refund",
  Blog: "/blog",

  // admin
  Dashboard: "/admin/dashboard",
  StaffDetails: "/admin/staffdetail",
  FeedbackList: "/admin/feedback",
  UserDetails: "/admin/userdetail",
  Settings: "/admin/setting",
  Staffpersonalditails: "/admin/staffdetail/:id",
  Recharge: "/admin/recharge",
  TransactionHistory: "/admin/transactionHistory",
  ListenerRequst: "/admin/listener-requst",
  ReportAndProblem: "/admin/report-and-problem",
  Sendnotification: "/admin/sendnotification",
  WebPageAdmin: "/admin/web-page",
};
